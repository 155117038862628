<template>
  <div
    class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" @click="close"></div>
    </div>

    <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
    <div
      class="relative bg-white rounded-lg px-4 pt-5 pb-4 shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="sm:flex sm:items-start">
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            Add Menu
          </h3>
          <div class="mt-2"></div>
        </div>
      </div>
      <div class="sm:ml-4 mt-4">
        <div>
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Name
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none leading-normal"
              type="text"
              v-model="form.name"
              ref="name"
              placeholder="Best Sellers"
            />
          </div>
        </div>
        <div class="mt-4">
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Link
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              class="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-md py-2 px-4 block w-full appearance-none leading-normal"
              type="text"
              v-model="form.link"
              placeholder="https://yourshopifylink.com/bestsellers"
            />
          </div>
        </div>
        <div class="mt-4">
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Color
          </label>
          <div class="mt-1 relative rounded-md shadow-sm">
            <input
              class="mt-1 form-input block w-full py-2 px-12 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
              v-model="form.color"
            />
            <verte
              picker="square"
              class="colorPicker"
              v-model="form.color"
              model="hex"
            ></verte>
          </div>
        </div>
        <div class="mt-4">
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Image
          </label>
          <div class="mt-1 relative rounded-md ">
            <div class="mt-1 flex items-center">
              <span class="h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                <svg
                  v-if="form.imageUrl.length === 0"
                  class="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"
                  />
                </svg>
                <img v-else :src="form.imageUrl" />
              </span>
              <input
                ref="fileUpload"
                type="file"
                accept="image/*"
                @change="uploadFile"
                hidden
              />
              <button
                type="button"
                v-if="!isFileUploading"
                class="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                @click="chooseFile"
              >
                Upload
              </button>
              <span class="mx-2">
                <Spinner v-if="isFileUploading"></Spinner>
              </span>
              <span
                class="text-blue-400 text-sm cursor-pointer"
                @click="openPicker"
              >
                Or Use an emoji
              </span>
            </div>
            <div class="mt-4">
              <Picker
                v-if="showEmojiPicker"
                set="twitter"
                @select="selectEmoji"
                title="Select emoji"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
          <button
            v-if="!menuItem"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:opacity-75"
            @click="addMenu"
            :disabled="$v.form.$invalid"
          >
            Add
          </button>
          <button
            v-if="menuItem"
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 disabled:opacity-75"
            @click="updateMenu"
            :disabled="$v.form.$invalid"
          >
            Update
          </button>
        </span>
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            @click="close"
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Spinner from "vue-simple-spinner";
import { required } from "vuelidate/lib/validators";
import { cloneDeep } from "lodash-es";

import Verte from "verte";

import "verte/dist/verte.css";

// Import data/twitter.json to reduce size, all.json contains data for
// all emoji sets.

// Import default CSS

import { Picker } from "emoji-mart-vue";

export default {
  components: {
    Verte,
    Spinner,
    Picker
  },
  props: {
    config: {
      type: Object
    },
    menuItem: {
      type: Object
    },
    menuPosition: { type: Number }
  },
  validations: {
    form: {
      name: {
        required
      },
      link: {
        required
      },
      color: {
        required
      },
      imageUrl: {
        required
      }
    }
  },
  data() {
    return {
      form: {
        name: "",
        link: "",
        color: "#ffffff",
        imageUrl: ""
      },
      isFileUploading: false,
      showEmojiPicker: false
      //   emojiIndex: emojiIndex
    };
  },
  mounted() {
    this.$refs.name.focus();
    if (this.menuItem) {
      this.form = {
        name: this.menuItem.name,
        link: this.menuItem.link,
        color: this.menuItem.color,
        imageUrl: this.menuItem.imageUrl
      };
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    onCreate() {
      this.$emit("create", { body: this.body, name: this.name });
      this.close();
    },
    onEdit() {
      this.$emit("edit", { body: this.body, name: this.name });
      this.close();
    },
    chooseFile() {
      this.$refs.fileUpload.click();
    },
    selectEmoji(emoji) {
      console.log({ emoji });
      this.form.imageUrl = `https://instaimages.carthike.com/img-twitter-72/${emoji.unified}.png`;
    },
    async uploadFile(event) {
      try {
        console.log({ event });
        this.isFileUploading = true;
        const file = event.target.files[0];

        const response = await this.$store.dispatch("getUploadUrl", {
          requestParams: { name: file.name, type: file.type }
        });

        const options = {
          headers: {
            "Content-Type": file.type
          }
        };
        await axios.put(response.url, file, options);
        this.form.imageUrl = response.imageUrl;
        this.isFileUploading = false;
      } catch (error) {
        this.isFileUploading = false;
        console.log("error while uploading file", error);
      }
    },
    addMenu() {
      const payload = cloneDeep(this.config);
      payload.menu.push({
        name: this.form.name,
        imageUrl: this.form.imageUrl,
        link: this.form.link,
        color: this.form.color
      });
      this.$store
        .dispatch("updateAppConfig", {
          requestParams: { config: payload }
        })
        .then(() => {
          this.close();
        });
    },
    openPicker() {
      this.showEmojiPicker = true;
    },
    updateMenu() {
      const payload = cloneDeep(this.config);

      payload.menu[this.menuPosition] = {
        name: this.form.name,
        imageUrl: this.form.imageUrl,
        link: this.form.link,
        color: this.form.color
      };
      this.$store
        .dispatch("updateAppConfig", {
          requestParams: { config: payload }
        })
        .then(() => {
          this.close();
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.colorPicker {
  position: absolute !important;
  top: 8px;
  left: 8px;
}
</style>
