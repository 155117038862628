var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "mt-4" },
      [
        _c("div", { staticClass: "sm:flex sm:items-center" }, [
          _vm._m(0),
          _c("div", { staticClass: "mt-4 sm:mt-0 sm:ml-16 sm:flex-none" }, [
            _c(
              "button",
              {
                staticClass:
                  "inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mx-2",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.openInstallAppModal(null)
                  }
                }
              },
              [_vm._v(" Install Menu on theme ")]
            ),
            _c(
              "button",
              {
                staticClass:
                  "inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    return _vm.openAddModal(null, 0)
                  }
                }
              },
              [_vm._v(" New Link ")]
            )
          ])
        ]),
        _c("div", { staticClass: "mt-8 flex flex-col" }, [
          _c(
            "div",
            { staticClass: "-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
                    },
                    [
                      _vm.config.menu && _vm.config.menu.length == 0
                        ? _c(
                            "div",
                            { staticClass: "text-center bg-white py-4" },
                            [
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "mx-auto h-12 w-12 text-gray-400",
                                  attrs: {
                                    fill: "none",
                                    viewBox: "0 0 24 24",
                                    stroke: "currentColor",
                                    "aria-hidden": "true"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "vector-effect": "non-scaling-stroke",
                                      "stroke-linecap": "round",
                                      "stroke-linejoin": "round",
                                      "stroke-width": "2",
                                      d:
                                        "M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                    }
                                  })
                                ]
                              ),
                              _c(
                                "h3",
                                {
                                  staticClass:
                                    "mt-2 text-sm font-medium text-gray-900"
                                },
                                [_vm._v(" No Links ")]
                              ),
                              _c(
                                "p",
                                { staticClass: "mt-1 text-sm text-gray-500" },
                                [
                                  _vm._v(
                                    " Get started by adding a link to the menu "
                                  )
                                ]
                              ),
                              _c("div", { staticClass: "mt-6" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openAddModal(null, 0)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "svg",
                                      {
                                        staticClass: "-ml-1 mr-2 h-5 w-5",
                                        attrs: {
                                          xmlns: "http://www.w3.org/2000/svg",
                                          viewBox: "0 0 20 20",
                                          fill: "currentColor",
                                          "aria-hidden": "true"
                                        }
                                      },
                                      [
                                        _c("path", {
                                          attrs: {
                                            "fill-rule": "evenodd",
                                            d:
                                              "M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z",
                                            "clip-rule": "evenodd"
                                          }
                                        })
                                      ]
                                    ),
                                    _vm._v(" New Link ")
                                  ]
                                )
                              ])
                            ]
                          )
                        : _c(
                            "table",
                            {
                              staticClass: "min-w-full divide-y divide-gray-300"
                            },
                            [
                              _vm._m(1),
                              _c(
                                "draggable",
                                {
                                  staticClass:
                                    "divide-y divide-gray-200 bg-white",
                                  attrs: { tag: "tbody" },
                                  on: {
                                    start: function($event) {
                                      _vm.drag = true
                                    },
                                    end: _vm.updatePosition
                                  },
                                  model: {
                                    value: _vm.config.menu,
                                    callback: function($$v) {
                                      _vm.$set(_vm.config, "menu", $$v)
                                    },
                                    expression: "config.menu"
                                  }
                                },
                                _vm._l(_vm.config.menu, function(
                                  menuItem,
                                  index
                                ) {
                                  return _c("tr", { key: index }, [
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "whitespace-nowrap py-4  text-sm font-medium text-gray-900 sm:pl-3"
                                      },
                                      [
                                        _c("img", {
                                          staticClass: "opacity-75 cursor-move",
                                          attrs: {
                                            src: require("../../assets/drag.png")
                                          }
                                        })
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(menuItem.name) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(menuItem.link) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(menuItem.color) + " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-indigo-600 hover:text-indigo-900 cursor-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.editMenu(
                                                  menuItem,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("Edit")]
                                        ),
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-red-600 hover:text-red-900 mx-4 cursor-pointer",
                                            on: {
                                              click: function($event) {
                                                return _vm.deleteMenu(
                                                  menuItem,
                                                  index
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v(" Delete ")]
                                        )
                                      ]
                                    )
                                  ])
                                }),
                                0
                              )
                            ],
                            1
                          )
                    ]
                  )
                ]
              )
            ]
          )
        ]),
        _vm.showAddModal
          ? _c("AddEditMenuModal", {
              attrs: {
                menuItem: _vm.currentMenuItem,
                config: _vm.config,
                menuPosition: _vm.currentMenuPosition
              },
              on: { close: _vm.closeAddModal }
            })
          : _vm._e(),
        _vm.showInstallAppModal
          ? _c("InstallAppModal", { on: { close: _vm.closeInstallAppModal } })
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sm:flex-auto" }, [
      _c("h1", { staticClass: "text-xl font-semibold text-gray-900" }, [
        _vm._v("Build your menu")
      ]),
      _c("p", { staticClass: "mt-2 text-sm text-gray-700" }, [
        _vm._v(" A list of all the links that you have added in your menu ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", { staticClass: "bg-gray-50" }, [
      _c("tr", [
        _c("th", {
          staticClass:
            "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
          attrs: { scope: "col" }
        }),
        _c(
          "th",
          {
            staticClass:
              "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6",
            attrs: { scope: "col" }
          },
          [_vm._v(" Name ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
            attrs: { scope: "col" }
          },
          [_vm._v(" Link ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
            attrs: { scope: "col" }
          },
          [_vm._v(" Color ")]
        ),
        _c(
          "th",
          {
            staticClass:
              "px-3 py-3.5 text-left text-sm font-semibold text-gray-900",
            attrs: { scope: "col" }
          },
          [_vm._v(" Action ")]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }