<template>
  <nav class="bg-gray-800">
    <div class="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
      <div class="relative flex items-center justify-between h-16">
        <div class="absolute inset-y-0 left-0 flex items-center sm:hidden">
          <!-- Mobile menu button-->
          <button
            class="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:bg-gray-700 focus:text-white transition duration-150 ease-in-out"
            aria-label="Main menu"
            aria-expanded="false"
            @click="isMenuOpen = !isMenuOpen"
          >
            <!-- Icon when menu is closed. -->
            <!-- Menu open: "hidden", Menu closed: "block" -->
            <svg
              :class="{ block: !isMenuOpen, hidden: isMenuOpen }"
              class=" h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Icon when menu is open. -->
            <!-- Menu open: "block", Menu closed: "hidden" -->
            <svg
              :class="{ block: isMenuOpen, hidden: !isMenuOpen }"
              class=" h-6 w-6"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div
          class="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start"
        >
          <div class="flex-shrink-0 items-center flex">
            <p class="text-white  flex items-center font-bold">
              Insta Story Menu
            </p>
          </div>
          <div class="hidden md:ml-6 md:flex md:items-center">
            <router-link
              to="/settings"
              class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              exact-active-class="text-white bg-gray-900"
              >Build the menu
            </router-link>
            <router-link
              to="/install"
              class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              exact-active-class="text-white bg-gray-900"
              >Install
            </router-link>
            <router-link
              to="/customize"
              class="ml-4 px-3 py-2 rounded-md text-sm font-medium leading-5 text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
              exact-active-class="text-white bg-gray-900"
              >Customize
            </router-link>
          </div>
        </div>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0"
        >
          <ProfileDropdown @showOnboarding="$emit('showOnboarding')" />
        </div>
      </div>
    </div>

    <div class=" sm:hidden" :class="{ block: isMenuOpen, hidden: !isMenuOpen }">
      <div class="px-2 pt-2 pb-3">
        <router-link
          to="/settings"
          class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >Build the menu
        </router-link>
        <router-link
          to="/install"
          class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >Install
        </router-link>
        <router-link
          to="/customize"
          class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700 transition duration-150 ease-in-out"
          >Customize
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
import ProfileDropdown from "./ProfileDropdown";

export default {
  name: "Topbar",
  components: {
    ProfileDropdown
  },
  data() {
    return {
      isMenuOpen: false
    };
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
