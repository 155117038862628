<template>
  <div class="h-full flex">
    <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
      <main class="flex-1 flex overflow-hidden">
        <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
          <div class="flex-1 flex xl:overflow-hidden">
            <!-- Main content -->
            <div class="flex-1 xl:overflow-y-auto">
              <div class="max-w-3xl mx-auto py-4 px-4 ">
                <PreviewBar :config="config" />
                <MenuList :config="config" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>

<script>
import PreviewBar from "../components/Common/PreviewBar.vue";
import MenuList from "../components/Settings/MenuList.vue";

export default {
  components: {
    PreviewBar,
    MenuList
  },
  data() {
    return {
      isLoading: false,
      showForm: false
    };
  },
  computed: {
    config() {
      return this.$store.getters.getConfig;
    }
  },
  created() {
    this.getAppConfig();
  },
  methods: {
    getAppConfig() {
      this.isLoading = true;
      this.$store.dispatch("getAppConfig").then(response => {
        this.isLoading = false;
        this.showForm = true;
        console.log(response);
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
