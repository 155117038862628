<template>
  <div>
    <div v-if="config.styles" class="py-2 max-w-3xl mx-auto">
      <div class="px-4 py-5  sm:p-6">
        <div class="md:grid md:grid-cols-3 md:gap-6">
          <div class="mt-5 md:mt-0 md:col-span-3">
            <div class="grid grid-cols-6 gap-6">
              <div class="col-span-3 sm:col-span-3 lg:col-span-3 relative">
                <label
                  for="city"
                  class="block text-sm font-medium leading-5 text-gray-700"
                  >Label Font Size</label
                >

                <input
                  class="mt-1 form-input block w-full py-2 px-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                  type="number"
                  v-model="config.styles.menuTextSize"
                />
              </div>
            </div>

            <div class="mt-4 sm:mt-0 sm:col-span-2">
              <div class="mt-8 border-t border-gray-200 pt-5">
                <div class="flex justify-end">
                  <span class="ml-3 inline-flex rounded-md shadow-sm">
                    <button
                      type="submit"
                      class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                      @click="save"
                    >
                      Save
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Notification :text="notificationText" :show="showNotification" />
      </div>
    </div>
  </div>
</template>

<script>
import Notification from "../Common/Notification.vue";

import "verte/dist/verte.css";

export default {
  components: {
    Notification
  },
  data() {
    return {
      notificationText: "",
      showNotification: false
    };
  },
  model: {
    prop: "config",
    event: "update"
  },
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  methods: {
    handleSwitch(value) {
      this.config.isEnabled = value;
    },
    save() {
      this.updateConfig();
    },
    updateConfig() {
      const automationConfig = { ...this.config };
      this.$store
        .dispatch("updateAppConfig", {
          requestParams: { config: automationConfig }
        })
        .then(() => {
          this.displayNotification("settings updated");
        });
    },
    addMore() {
      this.config.discounts.push({
        minAmount: 100,
        cta: "Add {{required_amount}} more to get 10% discount.",
        goalCompletionText: "Use 10OFF50 at checkout to get 10% off."
      });
    },
    displayNotification(text) {
      this.showNotification = true;
      this.notificationText = text;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
.colorPicker {
  position: absolute !important;
  top: 30px;
  left: 8px;
}
</style>
