<template>
  <div
    class="fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
  >
    <!--
    Background overlay, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0"
      To: "opacity-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100"
      To: "opacity-0"
  -->
    <div class="fixed inset-0 transition-opacity">
      <div class="absolute inset-0 bg-gray-500 opacity-75" @click="close"></div>
    </div>

    <!--
    Modal panel, show/hide based on modal state.

    Entering: "ease-out duration-300"
      From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      To: "opacity-100 translate-y-0 sm:scale-100"
    Leaving: "ease-in duration-200"
      From: "opacity-100 translate-y-0 sm:scale-100"
      To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  -->
    <div
      class="relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-headline"
    >
      <div class="sm:flex sm:items-start">
        <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
          <h3
            class="text-lg leading-6 font-medium text-gray-900"
            id="modal-headline"
          >
            Install App
          </h3>
          <div class="mt-2"></div>
        </div>
      </div>
      <div class="sm:ml-4 mt-4">
        <div class="bg-white shadow sm:rounded-lg">
          <div class="px-4 py-5 sm:p-6">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Install Using Script
            </h3>
            <div class="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                If your theme doesn't support Online 2.0, you can use this
                method.
              </p>
            </div>
            <div class="mt-5">
              <label class="block text-sm font-medium leading-5 text-gray-700">
                Choose Theme
              </label>
              <select
                v-model="themeId"
                id="country"
                name="country"
                autocomplete="country-name"
                class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2"
              >
                <option
                  v-for="(theme, index) of themes"
                  :key="index"
                  :value="theme.id"
                  >{{ theme.name }}</option
                >
              </select>
              <button
                type="button"
                class="inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto"
                @click="installScriptTag"
              >
                Install
              </button>
              <button
                type="button"
                class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              >
                Remove from Theme
              </button>
            </div>
          </div>
        </div>
        <div>
          <div class="mt-1 relative rounded-md shadow-sm">
            <span class="flex mt-4">
              <button
                type="button"
                class="inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto"
                @click="installScriptTag"
              >
                Install
              </button>
              <button
                type="button"
                class="inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto"
                @click="openEmbedUrl"
              >
                Activate on Online Store 2.0
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
          <button
            type="button"
            class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            @click="close"
          >
            Cancel
          </button>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {},
  data() {
    return {
      themes: [],
      themeId: null,
      isAppInstalled: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getThemes").then(response => {
        this.themes = response.themes;
      });
    },
    close() {
      this.$emit("close");
    },
    onCreate() {
      this.$emit("create", { body: this.body, name: this.name });
      this.close();
    },
    onEdit() {
      this.$emit("edit", { body: this.body, name: this.name });
      this.close();
    },
    installScriptTag() {
      this.$store
        .dispatch("installScriptTag", { themeId: this.themeId })
        .then(() => {
          this.isAppInstalled = true;
        });
    },
    openEmbedUrl() {}
  }
};
</script>

<style lang="scss" scoped></style>
