var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed bottom-0 inset-x-0 px-4 pb-4 sm:inset-0 sm:flex sm:items-center sm:justify-center"
    },
    [
      _c("div", { staticClass: "fixed inset-0 transition-opacity" }, [
        _c("div", {
          staticClass: "absolute inset-0 bg-gray-500 opacity-75",
          on: { click: _vm.close }
        })
      ]),
      _c(
        "div",
        {
          staticClass:
            "relative bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6",
          attrs: {
            role: "dialog",
            "aria-modal": "true",
            "aria-labelledby": "modal-headline"
          }
        },
        [
          _vm._m(0),
          _c("div", { staticClass: "sm:ml-4 mt-4" }, [
            _c("div", { staticClass: "bg-white shadow sm:rounded-lg" }, [
              _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
                _c(
                  "h3",
                  {
                    staticClass: "text-lg leading-6 font-medium text-gray-900"
                  },
                  [_vm._v(" Install Using Script ")]
                ),
                _vm._m(1),
                _c("div", { staticClass: "mt-5" }, [
                  _c(
                    "label",
                    {
                      staticClass:
                        "block text-sm font-medium leading-5 text-gray-700"
                    },
                    [_vm._v(" Choose Theme ")]
                  ),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.themeId,
                          expression: "themeId"
                        }
                      ],
                      staticClass:
                        "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2",
                      attrs: {
                        id: "country",
                        name: "country",
                        autocomplete: "country-name"
                      },
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.themeId = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        }
                      }
                    },
                    _vm._l(_vm.themes, function(theme, index) {
                      return _c(
                        "option",
                        { key: index, domProps: { value: theme.id } },
                        [_vm._v(_vm._s(theme.name))]
                      )
                    }),
                    0
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto",
                      attrs: { type: "button" },
                      on: { click: _vm.installScriptTag }
                    },
                    [_vm._v(" Install ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm",
                      attrs: { type: "button" }
                    },
                    [_vm._v(" Remove from Theme ")]
                  )
                ])
              ])
            ]),
            _c("div", [
              _c("div", { staticClass: "mt-1 relative rounded-md shadow-sm" }, [
                _c("span", { staticClass: "flex mt-4" }, [
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto",
                      attrs: { type: "button" },
                      on: { click: _vm.installScriptTag }
                    },
                    [_vm._v(" Install ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto",
                      attrs: { type: "button" },
                      on: { click: _vm.openEmbedUrl }
                    },
                    [_vm._v(" Activate on Online Store 2.0 ")]
                  )
                ])
              ])
            ])
          ]),
          _c(
            "div",
            { staticClass: "mt-5 sm:mt-4 sm:flex sm:flex-row-reverse" },
            [
              _c(
                "span",
                {
                  staticClass:
                    "mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto"
                },
                [
                  _c(
                    "button",
                    {
                      staticClass:
                        "inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5",
                      attrs: { type: "button" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" Cancel ")]
                  )
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sm:flex sm:items-start" }, [
      _c(
        "div",
        { staticClass: "mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left" },
        [
          _c(
            "h3",
            {
              staticClass: "text-lg leading-6 font-medium text-gray-900",
              attrs: { id: "modal-headline" }
            },
            [_vm._v(" Install App ")]
          ),
          _c("div", { staticClass: "mt-2" })
        ]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2 max-w-xl text-sm text-gray-500" }, [
      _c("p", [
        _vm._v(
          " If your theme doesn't support Online 2.0, you can use this method. "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }