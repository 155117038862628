var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "h-full flex" }, [
    _c("div", { staticClass: "flex-1 min-w-0 flex flex-col overflow-hidden" }, [
      _c("main", { staticClass: "flex-1 flex overflow-hidden" }, [
        _c(
          "div",
          {
            staticClass:
              "flex-1 flex flex-col overflow-y-auto xl:overflow-hidden"
          },
          [
            _c("div", { staticClass: "flex-1 flex xl:overflow-hidden" }, [
              _c("div", { staticClass: "flex-1 xl:overflow-y-auto" }, [
                _c(
                  "div",
                  { staticClass: "max-w-3xl mx-auto py-4 px-4 " },
                  [
                    _c("PreviewBar", { attrs: { config: _vm.config } }),
                    _c("MenuList", { attrs: { config: _vm.config } })
                  ],
                  1
                )
              ])
            ])
          ]
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }