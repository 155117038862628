var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.config.styles
      ? _c("div", { staticClass: "py-2 max-w-3xl mx-auto" }, [
          _c(
            "div",
            { staticClass: "px-4 py-5  sm:p-6" },
            [
              _c("div", { staticClass: "md:grid md:grid-cols-3 md:gap-6" }, [
                _c("div", { staticClass: "mt-5 md:mt-0 md:col-span-3" }, [
                  _c("div", { staticClass: "grid grid-cols-6 gap-6" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-span-3 sm:col-span-3 lg:col-span-3 relative"
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass:
                              "block text-sm font-medium leading-5 text-gray-700",
                            attrs: { for: "city" }
                          },
                          [_vm._v("Label Font Size")]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.config.styles.menuTextSize,
                              expression: "config.styles.menuTextSize"
                            }
                          ],
                          staticClass:
                            "mt-1 form-input block w-full py-2 px-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:shadow-outline-blue focus:border-blue-300 transition duration-150 ease-in-out sm:text-sm sm:leading-5",
                          attrs: { type: "number" },
                          domProps: { value: _vm.config.styles.menuTextSize },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.config.styles,
                                "menuTextSize",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "mt-4 sm:mt-0 sm:col-span-2" }, [
                    _c(
                      "div",
                      { staticClass: "mt-8 border-t border-gray-200 pt-5" },
                      [
                        _c("div", { staticClass: "flex justify-end" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-3 inline-flex rounded-md shadow-sm"
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass:
                                    "inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out",
                                  attrs: { type: "submit" },
                                  on: { click: _vm.save }
                                },
                                [_vm._v(" Save ")]
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ])
              ]),
              _c("Notification", {
                attrs: {
                  text: _vm.notificationText,
                  show: _vm.showNotification
                }
              })
            ],
            1
          )
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }