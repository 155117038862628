<template>
  <div class="relative">
    <p class="text-lg font-medium text-blue-gray-900">Preview</p>

    <div
      class="h-24 shadow-md rounded-md mt-4   bg-gray-200 opacity-1 flex items-center"
    >
      <ul class="flex">
        <li
          class="flex flex-col mx-2 items-center cursor-pointer"
          v-for="(menuItem, index) of config.menu"
          :key="index"
          @click="openLink(menuItem)"
        >
          <div
            class="flex-shrink-0 inline-flex rounded-full  "
            :style="{ backgroundColor: menuItem.color }"
          >
            <img
              class="h-12 w-12 rounded-full border-2 "
              :style="{ borderColor: menuItem.color }"
              :src="menuItem.imageUrl"
              alt=""
            />
          </div>
          <span
            class="text-sm text-gray-500 overflow-hidden whitespace-no-wrap text-center"
            style="    width: 100px;    text-overflow: ellipsis;"
            :style="{ 'font-size': config.styles.menuTextSize + 'px' }"
            >{{ menuItem.name }}</span
          >
        </li>
      </ul>
      <div
        class="w-full text-center"
        v-if="config && config.menu && config.menu.length == 0"
      >
        <p class="mt-1 text-sm text-gray-500">
          Create Links to start previewing your menu
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      isActionCompleted: false
    };
  },
  methods: {
    close() {},

    openLink(menuItem) {
      window.open(menuItem.link, "_blank");
    }
  }
};
</script>

<style lang="scss" scoped>
#magic-bar-wrapper {
  display: block;
  color: inherit;
}
#magicbar-content-container {
  opacity: 1;
  margin: 0px;
  padding: 0px;
  left: 0px;
  height: 58px;
  width: 100%;
  z-index: 100000;
  top: 0px;
}
#magicbar {
  text-align: center;
  margin: 0px;
  padding: 14px 10px;
  left: 0px;
  height: auto;
  width: 100%;
  box-sizing: border-box;
  border: none;
  position: absolute;
  background-color: black;
  color: rgb(255, 255, 255);
  font-size: 18px;
  line-height: 30px;
}
</style>
