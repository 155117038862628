var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "max-w-2xl mx-auto mt-4" }, [
    _c("div", { staticClass: "bg-white shadow sm:rounded-lg" }, [
      _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
        _c(
          "h3",
          { staticClass: "text-lg leading-6 font-medium text-gray-900" },
          [_vm._v(" Install Using Script ")]
        ),
        _vm._m(0),
        _c("div", { staticClass: "mt-5" }, [
          _c(
            "label",
            {
              staticClass: "block text-sm font-medium leading-5 text-gray-700"
            },
            [_vm._v(" Choose Theme ")]
          ),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.themeId,
                  expression: "themeId"
                }
              ],
              staticClass:
                "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2",
              attrs: {
                id: "country",
                name: "country",
                autocomplete: "country-name"
              },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.themeId = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                }
              }
            },
            _vm._l(_vm.themes, function(theme, index) {
              return _c(
                "option",
                { key: index, domProps: { value: theme.id } },
                [_vm._v(_vm._s(theme.name))]
              )
            }),
            0
          ),
          _c("div", { staticClass: "mt-4" }, [
            _c(
              "button",
              {
                staticClass:
                  "inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto mr-4",
                attrs: { type: "button", disabled: _vm.isInstalling },
                on: { click: _vm.installScriptTag }
              },
              [
                _vm.isInstalling
                  ? _c("Spinner", { attrs: { size: "small" } })
                  : _c("span", [_vm._v("Install")])
              ],
              1
            ),
            _c(
              "button",
              {
                staticClass:
                  "inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm",
                attrs: { type: "button", disabled: _vm.isRemoving },
                on: { click: _vm.removeScriptTag }
              },
              [
                _vm.isRemoving
                  ? _c("Spinner", { attrs: { size: "small" } })
                  : _c("span", [_vm._v("Remove from Theme")])
              ],
              1
            )
          ])
        ])
      ])
    ]),
    _c(
      "div",
      { staticClass: "bg-white shadow sm:rounded-lg mt-5" },
      [
        _c("div", { staticClass: "px-4 py-5 sm:p-6" }, [
          _c(
            "h3",
            { staticClass: "text-lg leading-6 font-medium text-gray-900" },
            [_vm._v(" Install Using Theme Editor ")]
          ),
          _vm._m(1),
          _c("div", { staticClass: "mt-4" }, [
            _c("span", { staticClass: "text-gray-600 text-sm font-medium" }, [
              _vm._v("Step 1 : ")
            ]),
            _c(
              "label",
              {
                staticClass: "block text-sm font-medium leading-5 text-gray-700"
              },
              [_vm._v(" Choose Theme ")]
            ),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.themeId,
                    expression: "themeId"
                  }
                ],
                staticClass:
                  "shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2",
                attrs: {
                  id: "country",
                  name: "country",
                  autocomplete: "country-name"
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.themeId = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  }
                }
              },
              _vm._l(_vm.themes, function(theme, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: theme.id } },
                  [_vm._v(_vm._s(theme.name))]
                )
              }),
              0
            )
          ]),
          _c("div", { staticClass: "mt-4" }, [
            _c("span", { staticClass: "text-gray-600 text-sm font-medium" }, [
              _vm._v("Step 2 : ")
            ]),
            _c("div"),
            _c(
              "button",
              {
                staticClass:
                  "inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                attrs: { type: "button" },
                on: { click: _vm.openEmbedUrl }
              },
              [_vm._v(" Open Theme Editor ")]
            )
          ]),
          _vm._m(2)
        ]),
        _c("Notification", {
          attrs: { text: _vm.notificationText, show: _vm.showNotification }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2 max-w-xl text-sm text-gray-500" }, [
      _c("p", [
        _vm._v(
          " If your theme doesn't support Online 2.0, you can use this method. "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-2 max-w-xl text-sm text-gray-500" }, [
      _c("p", [
        _vm._v(" If your theme support Online 2.0, you can use this method. ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-4" }, [
      _c("span", { staticClass: "text-gray-600 text-sm font-medium" }, [
        _vm._v("Step 3 : ")
      ]),
      _c("div"),
      _c("span", { staticClass: "text-gray-600 text-sm font-medium" }, [
        _vm._v("Click on Add section and Select Insta Menu Section ")
      ]),
      _c("img", {
        staticClass: "w-80",
        attrs: { src: require("../assets/osonboarding.png") }
      })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }