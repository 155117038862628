var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c("p", { staticClass: "text-lg font-medium text-blue-gray-900" }, [
      _vm._v("Preview")
    ]),
    _c(
      "div",
      {
        staticClass:
          "h-24 shadow-md rounded-md mt-4   bg-gray-200 opacity-1 flex items-center"
      },
      [
        _c(
          "ul",
          { staticClass: "flex" },
          _vm._l(_vm.config.menu, function(menuItem, index) {
            return _c(
              "li",
              {
                key: index,
                staticClass: "flex flex-col mx-2 items-center cursor-pointer",
                on: {
                  click: function($event) {
                    return _vm.openLink(menuItem)
                  }
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "flex-shrink-0 inline-flex rounded-full  ",
                    style: { backgroundColor: menuItem.color }
                  },
                  [
                    _c("img", {
                      staticClass: "h-12 w-12 rounded-full border-2 ",
                      style: { borderColor: menuItem.color },
                      attrs: { src: menuItem.imageUrl, alt: "" }
                    })
                  ]
                ),
                _c(
                  "span",
                  {
                    staticClass:
                      "text-sm text-gray-500 overflow-hidden whitespace-no-wrap text-center",
                    staticStyle: {
                      width: "100px",
                      "text-overflow": "ellipsis"
                    },
                    style: {
                      "font-size": _vm.config.styles.menuTextSize + "px"
                    }
                  },
                  [_vm._v(_vm._s(menuItem.name))]
                )
              ]
            )
          }),
          0
        ),
        _vm.config && _vm.config.menu && _vm.config.menu.length == 0
          ? _c("div", { staticClass: "w-full text-center" }, [
              _c("p", { staticClass: "mt-1 text-sm text-gray-500" }, [
                _vm._v(" Create Links to start previewing your menu ")
              ])
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }