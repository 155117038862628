<template>
  <div class="max-w-2xl mx-auto mt-4">
    <div class="bg-white shadow sm:rounded-lg">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Install Using Script
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            If your theme doesn't support Online 2.0, you can use this method.
          </p>
        </div>
        <div class="mt-5">
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Choose Theme
          </label>
          <select
            v-model="themeId"
            id="country"
            name="country"
            autocomplete="country-name"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2"
          >
            <option
              v-for="(theme, index) of themes"
              :key="index"
              :value="theme.id"
              >{{ theme.name }}</option
            >
          </select>
          <div class="mt-4">
            <button
              type="button"
              class="inline-flex justify-center  rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5 w-auto mr-4"
              @click="installScriptTag"
              :disabled="isInstalling"
            >
              <Spinner size="small" v-if="isInstalling"></Spinner>
              <span v-else>Install</span>
            </button>
            <button
              type="button"
              class="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              @click="removeScriptTag"
              :disabled="isRemoving"
            >
              <Spinner size="small" v-if="isRemoving"></Spinner>
              <span v-else>Remove from Theme</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="bg-white shadow sm:rounded-lg mt-5">
      <div class="px-4 py-5 sm:p-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Install Using Theme Editor
        </h3>
        <div class="mt-2 max-w-xl text-sm text-gray-500">
          <p>
            If your theme support Online 2.0, you can use this method.
          </p>
        </div>
        <div class="mt-4">
          <span class="text-gray-600 text-sm font-medium">Step 1 : </span>
          <label class="block text-sm font-medium leading-5 text-gray-700">
            Choose Theme
          </label>
          <select
            v-model="themeId"
            id="country"
            name="country"
            autocomplete="country-name"
            class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md border p-2"
          >
            <option
              v-for="(theme, index) of themes"
              :key="index"
              :value="theme.id"
              >{{ theme.name }}</option
            >
          </select>
        </div>
        <div class="mt-4">
          <span class="text-gray-600 text-sm font-medium">Step 2 : </span>
          <div></div>
          <button
            type="button"
            class="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            @click="openEmbedUrl"
          >
            Open Theme Editor
          </button>
        </div>
        <div class="mt-4">
          <span class="text-gray-600 text-sm font-medium">Step 3 : </span>
          <div></div>
          <span class="text-gray-600 text-sm font-medium"
            >Click on Add section and Select Insta Menu Section
          </span>

          <img class="w-80" src="../assets/osonboarding.png" />
        </div>
      </div>
      <Notification :text="notificationText" :show="showNotification" />
    </div>
  </div>
</template>

<script>
import Notification from "../components/Common/Notification.vue";
import Spinner from "vue-simple-spinner";
export default {
  components: {
    Notification,
    Spinner
  },
  props: {},
  data() {
    return {
      themes: [],
      themeId: null,
      isAppInstalled: false,
      notificationText: "",
      showNotification: false,
      isInstalling: false,
      isRemoving: false
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.$store.dispatch("getThemes").then(response => {
        this.themes = response.themes;
      });
    },
    close() {
      this.$emit("close");
    },
    onCreate() {
      this.$emit("create", { body: this.body, name: this.name });
      this.close();
    },
    onEdit() {
      this.$emit("edit", { body: this.body, name: this.name });
      this.close();
    },
    installScriptTag() {
      this.isInstalling = true;
      this.$store
        .dispatch("installScriptTag", { themeId: this.themeId })
        .then(() => {
          this.isInstalling = false;
          this.displayNotification("Menu added to theme");
        })
        .catch(() => {
          this.isInstalling = false;
        });
    },
    removeScriptTag() {
      this.isRemoving = true;
      this.$store
        .dispatch("uninstallScriptTag", { themeId: this.themeId })
        .then(() => {
          this.isRemoving = false;
          this.displayNotification("Menu removed from theme");
        })
        .catch(() => {
          this.isRemoving = false;
        });
    },
    openEmbedUrl() {
      this.$store
        .dispatch("getEmbedUrl", { themeId: this.themeId })
        .then(response => {
          if (response.url) {
            window.open(response.url);
          }
        });
    },
    displayNotification(text) {
      this.showNotification = true;
      this.notificationText = text;
      setTimeout(() => {
        this.showNotification = false;
      }, 3000);
    }
  }
};
</script>

<style lang="scss" scoped>
.vue-simple-spinner {
  width: 16px;
  height: 16px;
}
</style>
