<template>
  <div>
    <div class="mt-4">
      <div class="sm:flex sm:items-center">
        <div class="sm:flex-auto">
          <h1 class="text-xl font-semibold text-gray-900">Build your menu</h1>
          <p class="mt-2 text-sm text-gray-700">
            A list of all the links that you have added in your menu
          </p>
        </div>
        <div class="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto mx-2"
            @click="openInstallAppModal(null)"
          >
            Install Menu on theme
          </button>
          <button
            type="button"
            class="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            @click="openAddModal(null, 0)"
          >
            New Link
          </button>
        </div>
      </div>
      <div class="mt-8 flex flex-col">
        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div
            class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8"
          >
            <div
              class="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg"
            >
              <div
                v-if="config.menu && config.menu.length == 0"
                class="text-center bg-white py-4"
              >
                <svg
                  class="mx-auto h-12 w-12 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    vector-effect="non-scaling-stroke"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                  />
                </svg>
                <h3 class="mt-2 text-sm font-medium text-gray-900">
                  No Links
                </h3>
                <p class="mt-1 text-sm text-gray-500">
                  Get started by adding a link to the menu
                </p>
                <div class="mt-6">
                  <button
                    type="button"
                    class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    @click="openAddModal(null, 0)"
                  >
                    <!-- Heroicon name: solid/plus -->
                    <svg
                      class="-ml-1 mr-2 h-5 w-5"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                        clip-rule="evenodd"
                      />
                    </svg>
                    New Link
                  </button>
                </div>
              </div>

              <table v-else class="min-w-full divide-y divide-gray-300">
                <thead class="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    ></th>
                    <th
                      scope="col"
                      class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Link
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Color
                    </th>
                    <th
                      scope="col"
                      class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Action
                    </th>
                  </tr>
                </thead>

                <draggable
                  tag="tbody"
                  class="divide-y divide-gray-200 bg-white"
                  v-model="config.menu"
                  @start="drag = true"
                  @end="updatePosition"
                >
                  <tr v-for="(menuItem, index) of config.menu" :key="index">
                    <td
                      class="whitespace-nowrap py-4  text-sm font-medium text-gray-900 sm:pl-3"
                    >
                      <img
                        src="../../assets/drag.png"
                        class="opacity-75 cursor-move"
                      />
                    </td>
                    <td
                      class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6"
                    >
                      {{ menuItem.name }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ menuItem.link }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      {{ menuItem.color }}
                    </td>
                    <td
                      class="whitespace-nowrap px-3 py-4 text-sm text-gray-500"
                    >
                      <a
                        class="text-indigo-600 hover:text-indigo-900 cursor-pointer"
                        @click="editMenu(menuItem, index)"
                        >Edit</a
                      >
                      <a
                        class="text-red-600 hover:text-red-900 mx-4 cursor-pointer"
                        @click="deleteMenu(menuItem, index)"
                      >
                        Delete
                      </a>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
      <AddEditMenuModal
        :menuItem="currentMenuItem"
        :config="config"
        :menuPosition="currentMenuPosition"
        v-if="showAddModal"
        @close="closeAddModal"
      />
      <InstallAppModal
        v-if="showInstallAppModal"
        @close="closeInstallAppModal"
      />
    </div>
  </div>
</template>

<script>
import AddEditMenuModal from "./AddEditMenuModal.vue";
import InstallAppModal from "./InstallAppModal.vue";
import { cloneDeep } from "lodash-es";
import draggable from "vuedraggable";

export default {
  name: "MenuList",
  props: {
    config: {
      type: Object
    }
  },
  components: {
    AddEditMenuModal,
    InstallAppModal,
    draggable
  },
  data() {
    return {
      currentMenuItem: null,
      currentMenuPosition: 0,
      showAddModal: false,
      showInstallAppModal: false
    };
  },
  methods: {
    openAddModal(menuItem, index) {
      this.currentMenuItem = menuItem;
      this.currentMenuPosition = index;
      this.showAddModal = true;
    },
    closeAddModal() {
      this.currentMenuItem = null;
      this.showAddModal = false;
    },
    editMenu(menuItem, index) {
      this.openAddModal(menuItem, index);
    },
    deleteMenu(menuItem, index) {
      const payload = cloneDeep(this.config);

      payload.menu.splice(index, 1);
      this.$store.dispatch("updateAppConfig", {
        requestParams: { config: payload }
      });
    },
    openInstallAppModal() {
      this.$router.push({ name: "Install" });
    },
    closeInstallAppModal() {
      this.showInstallAppModal = false;
    },
    updatePosition() {
      const payload = cloneDeep(this.config);

      this.$store.dispatch("updateAppConfig", {
        requestParams: { config: payload }
      });
    }
  }
};
</script>

<style lang="scss" scoped></style>
